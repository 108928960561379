import React from 'react';

import { Text, Button, ButtonPriority, ButtonSize } from 'wix-ui-tpa/cssVars';

import { FCWithChildren } from '@wix/challenges-web-library';
import {
  useEnvironment,
  useTranslation,
  useBi,
  useExperiments,
} from '@wix/yoshi-flow-editor';
import { Challenges } from '../../../../../editor/types/Experiments';
import { memberWebAppButtonClick as memberWebAppButtonClickV2 } from '@wix/bi-logger-challenges-member-web/v2';
import { ButtonNames } from '../../../../../contexts/main/biInterfaces';
import { dataHooks } from '@wix/data-hooks';
import { useUser } from '../../../../../contexts/User/UserContext';
import { useVisitorPageBase } from '../../views/VisitorPage/contexts/VisitorPageBase/VisitorPageBaseContext';

import { classes, st } from './ProgramUnavailable.st.css';

export interface IProgramUnavailableProps {
  className?: string;
  type: 'SidebarGeneral' | 'SidebarOverview' | 'JoinModal';
  prefix?: React.ReactNode;
}

export const programUnavailableDataHooks = dataHooks<{
  main;
}>('program-unavailable');

export const ProgramUnavailable: FCWithChildren<IProgramUnavailableProps> = ({
  className,
  type,
  prefix,
}: IProgramUnavailableProps) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const { isParticipantInLockedState } = useUser();
  const visitorPageContext = useVisitorPageBase();
  const { experiments } = useExperiments();
  const isPremiumOOIEnabled = experiments.enabled(Challenges.enablePremium);

  if (!isPremiumOOIEnabled) {
    return null;
  }

  return (
    <section
      className={`${st(classes.root, {
        mobile: isMobile,
        type: type.toLowerCase(),
      })} ${className}`}
      data-hook={programUnavailableDataHooks.main()}
    >
      {prefix || null}
      <div className={classes.content}>
        <h4 className={classes.title}>
          {t('live.modal.program-unavailable.title')}
        </h4>
        <Text tagName="p" className={classes.description}>
          {t('live.modal.program-unavailable.description')}
        </Text>

        {type === 'JoinModal' ? (
          <Button
            className={classes.button}
            fullWidth={isMobile}
            priority={ButtonPriority.secondary}
            size={ButtonSize.small}
            onClick={async () => {
              visitorPageContext?.setIsPremiumOutdatedModalOpened(false);
              await bi.report(
                memberWebAppButtonClickV2({
                  buttonName: ButtonNames.PremiumOutdatedGotIt,
                }),
              );
            }}
          >
            {t('live.modal.program-unavailable.cta')}
          </Button>
        ) : null}
      </div>
    </section>
  );
};
ProgramUnavailable.displayName = 'ProgramUnavailable';
ProgramUnavailable.defaultProps = {
  className: '',
};
